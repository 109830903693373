export const countryCodes = [
  {
    name: 'Afghanistan',
    dial_code: '+93',
    code: 'af',
  },

  {
    name: 'Albania',
    dial_code: '+355',
    code: 'al',
  },
  {
    name: 'Algeria',
    dial_code: '+213',
    code: 'dz',
  },
  {
    name: 'AmericanSamoa',
    dial_code: '+1684',
    code: 'as',
  },
  {
    name: 'Andorra',
    dial_code: '+376',
    code: 'ad',
  },
  {
    name: 'Angola',
    dial_code: '+244',
    code: 'ao',
  },
  {
    name: 'Anguilla',
    dial_code: '+1264',
    code: 'ai',
  },
  {
    name: 'Antarctica',
    dial_code: '+672',
    code: 'aq',
  },
  {
    name: 'Antigua and Barbuda',
    dial_code: '+1268',
    code: 'ag',
  },
  {
    name: 'Argentina',
    dial_code: '+54',
    code: 'ar',
  },
  {
    name: 'Armenia',
    dial_code: '+374',
    code: 'am',
  },
  {
    name: 'Aruba',
    dial_code: '+297',
    code: 'aw',
  },
  {
    name: 'Australia',
    dial_code: '+61',
    code: 'au',
  },
  {
    name: 'Austria',
    dial_code: '+43',
    code: 'at',
  },
  {
    name: 'Azerbaijan',
    dial_code: '+994',
    code: 'az',
  },
  {
    name: 'Bahamas',
    dial_code: '+1242',
    code: 'bs',
  },
  {
    name: 'Bahrain',
    dial_code: '+973',
    code: 'bh',
  },
  {
    name: 'Bangladesh',
    dial_code: '+880',
    code: 'bd',
  },
  {
    name: 'Barbados',
    dial_code: '+1246',
    code: 'bb',
  },
  {
    name: 'Belarus',
    dial_code: '+375',
    code: 'by',
  },
  {
    name: 'Belgium',
    dial_code: '+32',
    code: 'be',
  },
  {
    name: 'Belize',
    dial_code: '+501',
    code: 'bz',
  },
  {
    name: 'Benin',
    dial_code: '+229',
    code: 'bj',
  },
  {
    name: 'Bermuda',
    dial_code: '+1441',
    code: 'bm',
  },
  {
    name: 'Bhutan',
    dial_code: '+975',
    code: 'bt',
  },
  {
    name: 'Bolivia, Plurinational State of',
    dial_code: '+591',
    code: 'bo',
  },
  {
    name: 'Bosnia and Herzegovina',
    dial_code: '+387',
    code: 'ba',
  },
  {
    name: 'Botswana',
    dial_code: '+267',
    code: 'bw',
  },
  {
    name: 'Brazil',
    dial_code: '+55',
    code: 'br',
  },
  {
    name: 'British Indian Ocean Territory',
    dial_code: '+246',
    code: 'io',
  },
  {
    name: 'Brunei Darussalam',
    dial_code: '+673',
    code: 'bn',
  },
  {
    name: 'Bulgaria',
    dial_code: '+359',
    code: 'bg',
  },
  {
    name: 'Burkina Faso',
    dial_code: '+226',
    code: 'bf',
  },
  {
    name: 'Burundi',
    dial_code: '+257',
    code: 'bi',
  },
  {
    name: 'Cambodia',
    dial_code: '+855',
    code: 'kh',
  },
  {
    name: 'Cameroon',
    dial_code: '+237',
    code: 'cm',
  },
  {
    name: 'Canada',
    dial_code: '+1',
    code: 'ca',
  },
  {
    name: 'Cape Verde',
    dial_code: '+238',
    code: 'cv',
  },
  {
    name: 'Cayman Islands',
    dial_code: '+ 345',
    code: 'ky',
  },
  {
    name: 'Central African Republic',
    dial_code: '+236',
    code: 'cf',
  },
  {
    name: 'Chad',
    dial_code: '+235',
    code: 'td',
  },
  {
    name: 'Chile',
    dial_code: '+56',
    code: 'cl',
  },
  {
    name: 'China',
    dial_code: '+86',
    code: 'cn',
  },
  {
    name: 'Christmas Island',
    dial_code: '+61',
    code: 'cx',
  },
  {
    name: 'Cocos (Keeling) Islands',
    dial_code: '+61',
    code: 'cc',
  },
  {
    name: 'Colombia',
    dial_code: '+57',
    code: 'co',
  },
  {
    name: 'Comoros',
    dial_code: '+269',
    code: 'km',
  },
  {
    name: 'Congo',
    dial_code: '+242',
    code: 'cg',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    dial_code: '+243',
    code: 'cd',
  },
  {
    name: 'Cook Islands',
    dial_code: '+682',
    code: 'ck',
  },
  {
    name: 'Costa Rica',
    dial_code: '+506',
    code: 'cr',
  },
  {
    name: "Cote d'Ivoire",
    dial_code: '+225',
    code: 'ci',
  },
  {
    name: 'Croatia',
    dial_code: '+385',
    code: 'hr',
  },
  {
    name: 'Cuba',
    dial_code: '+53',
    code: 'cu',
  },
  {
    name: 'Cyprus',
    dial_code: '+357',
    code: 'cy',
  },
  {
    name: 'Czech Republic',
    dial_code: '+420',
    code: 'cz',
  },
  {
    name: 'Denmark',
    dial_code: '+45',
    code: 'dk',
  },
  {
    name: 'Djibouti',
    dial_code: '+253',
    code: 'dj',
  },
  {
    name: 'Dominica',
    dial_code: '+1767',
    code: 'dm',
  },
  {
    name: 'Dominican Republic',
    dial_code: '+1849',
    code: 'do',
  },
  {
    name: 'Ecuador',
    dial_code: '+593',
    code: 'ec',
  },
  {
    name: 'Egypt',
    dial_code: '+20',
    code: 'eg',
  },
  {
    name: 'El Salvador',
    dial_code: '+503',
    code: 'sv',
  },
  {
    name: 'Equatorial Guinea',
    dial_code: '+240',
    code: 'gq',
  },
  {
    name: 'Eritrea',
    dial_code: '+291',
    code: 'er',
  },
  {
    name: 'Estonia',
    dial_code: '+372',
    code: 'ee',
  },
  {
    name: 'Ethiopia',
    dial_code: '+251',
    code: 'et',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    dial_code: '+500',
    code: 'fk',
  },
  {
    name: 'Faroe Islands',
    dial_code: '+298',
    code: 'fo',
  },
  {
    name: 'Fiji',
    dial_code: '+679',
    code: 'fj',
  },
  {
    name: 'Finland',
    dial_code: '+358',
    code: 'fi',
  },
  {
    name: 'France',
    dial_code: '+33',
    code: 'fr',
  },
  {
    name: 'French Guiana',
    dial_code: '+594',
    code: 'gf',
  },
  {
    name: 'French Polynesia',
    dial_code: '+689',
    code: 'pf',
  },
  {
    name: 'Gabon',
    dial_code: '+241',
    code: 'ga',
  },
  {
    name: 'Gambia',
    dial_code: '+220',
    code: 'gm',
  },
  {
    name: 'Georgia',
    dial_code: '+995',
    code: 'ge',
  },
  {
    name: 'Germany',
    dial_code: '+49',
    code: 'de',
  },
  {
    name: 'Ghana',
    dial_code: '+233',
    code: 'gh',
  },
  {
    name: 'Gibraltar',
    dial_code: '+350',
    code: 'gi',
  },
  {
    name: 'Greece',
    dial_code: '+30',
    code: 'gr',
  },
  {
    name: 'Greenland',
    dial_code: '+299',
    code: 'gl',
  },
  {
    name: 'Grenada',
    dial_code: '+1473',
    code: 'gd',
  },
  {
    name: 'Guadeloupe',
    dial_code: '+590',
    code: 'gp',
  },
  {
    name: 'Guam',
    dial_code: '+1671',
    code: 'gu',
  },
  {
    name: 'Guatemala',
    dial_code: '+502',
    code: 'gt',
  },
  {
    name: 'Guernsey',
    dial_code: '+44',
    code: 'gg',
  },
  {
    name: 'Guinea',
    dial_code: '+224',
    code: 'gn',
  },
  {
    name: 'Guinea-Bissau',
    dial_code: '+245',
    code: 'gw',
  },
  {
    name: 'Guyana',
    dial_code: '+595',
    code: 'gy',
  },
  {
    name: 'Haiti',
    dial_code: '+509',
    code: 'ht',
  },
  {
    name: 'Holy See (Vatican City State)',
    dial_code: '+379',
    code: 'va',
  },
  {
    name: 'Honduras',
    dial_code: '+504',
    code: 'hn',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'hk',
  },
  {
    name: 'Hungary',
    dial_code: '+36',
    code: 'hu',
  },
  {
    name: 'Iceland',
    dial_code: '+354',
    code: 'is',
  },
  {
    name: 'India',
    dial_code: '+91',
    code: 'in',
  },
  {
    name: 'Indonesia',
    dial_code: '+62',
    code: 'id',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    dial_code: '+98',
    code: 'ir',
  },
  {
    name: 'Iraq',
    dial_code: '+964',
    code: 'iq',
  },
  {
    name: 'Ireland',
    dial_code: '+353',
    code: 'ie',
  },
  {
    name: 'Isle of Man',
    dial_code: '+44',
    code: 'im',
  },
  {
    name: 'Israel',
    dial_code: '+972',
    code: 'il',
  },
  {
    name: 'Italy',
    dial_code: '+39',
    code: 'it',
  },
  {
    name: 'Jamaica',
    dial_code: '+1876',
    code: 'jm',
  },
  {
    name: 'Japan',
    dial_code: '+81',
    code: 'jp',
  },
  {
    name: 'Jersey',
    dial_code: '+44',
    code: 'je',
  },
  {
    name: 'Jordan',
    dial_code: '+962',
    code: 'jo',
  },
  {
    name: 'Kazakhstan',
    dial_code: '+77',
    code: 'kz',
  },
  {
    name: 'Kenya',
    dial_code: '+254',
    code: 'ke',
  },
  {
    name: 'Kiribati',
    dial_code: '+686',
    code: 'ki',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dial_code: '+850',
    code: 'kp',
  },
  {
    name: 'Korea, Republic of South Korea',
    dial_code: '+82',
    code: 'kr',
  },
  {
    name: 'Kuwait',
    dial_code: '+965',
    code: 'kw',
  },
  {
    name: 'Kyrgyzstan',
    dial_code: '+996',
    code: 'kg',
  },
  {
    name: 'Laos',
    dial_code: '+856',
    code: 'la',
  },
  {
    name: 'Latvia',
    dial_code: '+371',
    code: 'lv',
  },
  {
    name: 'Lebanon',
    dial_code: '+961',
    code: 'lb',
  },
  {
    name: 'Lesotho',
    dial_code: '+266',
    code: 'ls',
  },
  {
    name: 'Liberia',
    dial_code: '+231',
    code: 'lr',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    dial_code: '+218',
    code: 'ly',
  },
  {
    name: 'Liechtenstein',
    dial_code: '+423',
    code: 'li',
  },
  {
    name: 'Lithuania',
    dial_code: '+370',
    code: 'lt',
  },
  {
    name: 'Luxembourg',
    dial_code: '+352',
    code: 'lu',
  },
  {
    name: 'Macao',
    dial_code: '+853',
    code: 'mo',
  },
  {
    name: 'Macedonia',
    dial_code: '+389',
    code: 'mk',
  },
  {
    name: 'Madagascar',
    dial_code: '+261',
    code: 'mg',
  },
  {
    name: 'Malawi',
    dial_code: '+265',
    code: 'mw',
  },
  {
    name: 'Malaysia',
    dial_code: '+60',
    code: 'my',
  },
  {
    name: 'Maldives',
    dial_code: '+960',
    code: 'mv',
  },
  {
    name: 'Mali',
    dial_code: '+223',
    code: 'ml',
  },
  {
    name: 'Malta',
    dial_code: '+356',
    code: 'mt',
  },
  {
    name: 'Marshall Islands',
    dial_code: '+692',
    code: 'mh',
  },
  {
    name: 'Martinique',
    dial_code: '+596',
    code: 'mq',
  },
  {
    name: 'Mauritania',
    dial_code: '+222',
    code: 'mr',
  },
  {
    name: 'Mauritius',
    dial_code: '+230',
    code: 'mu',
  },
  {
    name: 'Mayotte',
    dial_code: '+262',
    code: 'yt',
  },
  {
    name: 'Mexico',
    dial_code: '+52',
    code: 'mx',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    dial_code: '+691',
    code: 'fm',
  },
  {
    name: 'Moldova',
    dial_code: '+373',
    code: 'md',
  },
  {
    name: 'Monaco',
    dial_code: '+377',
    code: 'mc',
  },
  {
    name: 'Mongolia',
    dial_code: '+976',
    code: 'mn',
  },
  {
    name: 'Montenegro',
    dial_code: '+382',
    code: 'me',
  },
  {
    name: 'Montserrat',
    dial_code: '+1664',
    code: 'ms',
  },
  {
    name: 'Morocco',
    dial_code: '+212',
    code: 'ma',
  },
  {
    name: 'Mozambique',
    dial_code: '+258',
    code: 'mz',
  },
  {
    name: 'Myanmar',
    dial_code: '+95',
    code: 'mm',
  },
  {
    name: 'Namibia',
    dial_code: '+264',
    code: 'na',
  },
  {
    name: 'Nauru',
    dial_code: '+674',
    code: 'nr',
  },
  {
    name: 'Nepal',
    dial_code: '+977',
    code: 'np',
  },

  {
    name: 'Netherlands Antilles',
    dial_code: '+599',
    code: 'an',
  },
  {
    name: 'New Caledonia',
    dial_code: '+687',
    code: 'nc',
  },
  {
    name: 'New Zealand',
    dial_code: '+64',
    code: 'nz',
  },
  {
    name: 'Nicaragua',
    dial_code: '+505',
    code: 'ni',
  },
  {
    name: 'Niger',
    dial_code: '+227',
    code: 'ne',
  },
  {
    name: 'Nigeria',
    dial_code: '+234',
    code: 'ng',
  },
  {
    name: 'Niue',
    dial_code: '+683',
    code: 'nu',
  },
  {
    name: 'Norfolk Island',
    dial_code: '+672',
    code: 'nf',
  },
  {
    name: 'Northern Mariana Islands',
    dial_code: '+1670',
    code: 'mp',
  },
  {
    name: 'Norway',
    dial_code: '+47',
    code: 'no',
  },
  {
    name: 'Oman',
    dial_code: '+968',
    code: 'om',
  },
  {
    name: 'Pakistan',
    dial_code: '+92',
    code: 'pk',
  },
  {
    name: 'Panama',
    dial_code: '+507',
    code: 'pa',
  },
  {
    name: 'Papua New Guinea',
    dial_code: '+675',
    code: 'pg',
  },
  {
    name: 'Peru',
    dial_code: '+51',
    code: 'pe',
  },
  {
    name: 'Philippines',
    dial_code: '+63',
    code: 'ph',
  },
  {
    name: 'Poland',
    dial_code: '+48',
    code: 'pl',
  },

  {
    name: 'Qatar',
    dial_code: '+974',
    code: 'qa',
  },
  {
    name: 'Romania',
    dial_code: '+40',
    code: 'ro',
  },
  {
    name: 'Russia',
    dial_code: '+7',
    code: 'ru',
  },
  {
    name: 'Rwanda',
    dial_code: '+250',
    code: 'rw',
  },
  {
    name: 'Reunion',
    dial_code: '+262',
    code: 're',
  },
  {
    name: 'Saint Barthelemy',
    dial_code: '+590',
    code: 'bl',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    dial_code: '+290',
    code: 'sh',
  },
  {
    name: 'Saint Kitts and Nevis',
    dial_code: '+1869',
    code: 'kn',
  },
  {
    name: 'Saint Lucia',
    dial_code: '+1758',
    code: 'lc',
  },
  {
    name: 'Saint Martin',
    dial_code: '+590',
    code: 'mf',
  },
  {
    name: 'Saint Pierre and Miquelon',
    dial_code: '+508',
    code: 'pm',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    dial_code: '+1784',
    code: 'vc',
  },
  {
    name: 'Samoa',
    dial_code: '+685',
    code: 'ws',
  },
  {
    name: 'San Marino',
    dial_code: '+378',
    code: 'sm',
  },
  {
    name: 'Sao Tome and Principe',
    dial_code: '+239',
    code: 'st',
  },
  {
    name: 'Saudi Arabia',
    dial_code: '+966',
    code: 'sa',
  },
  {
    name: 'Senegal',
    dial_code: '+221',
    code: 'sn',
  },
  {
    name: 'Serbia',
    dial_code: '+381',
    code: 'rs',
  },
  {
    name: 'Seychelles',
    dial_code: '+248',
    code: 'sc',
  },
  {
    name: 'Sierra Leone',
    dial_code: '+232',
    code: 'sl',
  },
  {
    name: 'Singapore',
    dial_code: '+65',
    code: 'sg',
  },
  {
    name: 'Slovakia',
    dial_code: '+421',
    code: 'sk',
  },
  {
    name: 'Slovenia',
    dial_code: '+386',
    code: 'si',
  },
  {
    name: 'Solomon Islands',
    dial_code: '+677',
    code: 'sb',
  },
  {
    name: 'Somalia',
    dial_code: '+252',
    code: 'so',
  },
  {
    name: 'South Africa',
    dial_code: '+27',
    code: 'za',
  },
  {
    name: 'South Sudan',
    dial_code: '+211',
    code: 'ss',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    dial_code: '+500',
    code: 'gs',
  },
  {
    name: 'Spain',
    dial_code: '+34',
    code: 'es',
  },
  {
    name: 'Sri Lanka',
    dial_code: '+94',
    code: 'lk',
  },
  {
    name: 'Sudan',
    dial_code: '+249',
    code: 'sd',
  },
  {
    name: 'Suriname',
    dial_code: '+597',
    code: 'sr',
  },
  {
    name: 'Svalbard and Jan Mayen',
    dial_code: '+47',
    code: 'sj',
  },
  {
    name: 'Swaziland',
    dial_code: '+268',
    code: 'sz',
  },
  {
    name: 'Sweden',
    dial_code: '+46',
    code: 'se',
  },
  {
    name: 'Switzerland',
    dial_code: '+41',
    code: 'ch',
  },
  {
    name: 'Syrian Arab Republic',
    dial_code: '+963',
    code: 'sy',
  },
  {
    name: 'Taiwan',
    dial_code: '+886',
    code: 'tw',
  },
  {
    name: 'Tajikistan',
    dial_code: '+992',
    code: 'tj',
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    dial_code: '+255',
    code: 'tz',
  },
  {
    name: 'Thailand',
    dial_code: '+66',
    code: 'th',
  },
  {
    name: 'Timor-Leste',
    dial_code: '+670',
    code: 'tl',
  },
  {
    name: 'Togo',
    dial_code: '+228',
    code: 'tg',
  },
  {
    name: 'Tokelau',
    dial_code: '+690',
    code: 'tk',
  },
  {
    name: 'Tonga',
    dial_code: '+676',
    code: 'to',
  },
  {
    name: 'Trinidad and Tobago',
    dial_code: '+1868',
    code: 'tt',
  },
  {
    name: 'Tunisia',
    dial_code: '+216',
    code: 'tn',
  },
  {
    name: 'Turkey',
    dial_code: '+90',
    code: 'tr',
  },
  {
    name: 'Turkmenistan',
    dial_code: '+993',
    code: 'tm',
  },
  {
    name: 'Turks and Caicos Islands',
    dial_code: '+1649',
    code: 'tc',
  },
  {
    name: 'Tuvalu',
    dial_code: '+688',
    code: 'tv',
  },
  {
    name: 'Uganda',
    dial_code: '+256',
    code: 'ug',
  },
  {
    name: 'Ukraine',
    dial_code: '+380',
    code: 'ua',
  },
  {
    name: 'United Arab Emirates',
    dial_code: '+971',
    code: 'ae',
  },
  {
    name: 'United Kingdom',
    dial_code: '+44',
    code: 'gb',
  },
  {
    name: 'United States',
    dial_code: '+1',
    code: 'us',
  },
  {
    name: 'Uruguay',
    dial_code: '+598',
    code: 'uy',
  },
  {
    name: 'Uzbekistan',
    dial_code: '+998',
    code: 'uz',
  },
  {
    name: 'Vanuatu',
    dial_code: '+678',
    code: 'vu',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    dial_code: '+58',
    code: 've',
  },
  {
    name: 'Vietnam',
    dial_code: '+84',
    code: 'vn',
  },
  {
    name: 'Virgin Islands, British',
    dial_code: '+1284',
    code: 'vg',
  },
  {
    name: 'Virgin Islands, U.S.',
    dial_code: '+1340',
    code: 'vi',
  },
  {
    name: 'Wallis and Futuna',
    dial_code: '+681',
    code: 'wf',
  },
  {
    name: 'Yemen',
    dial_code: '+967',
    code: 'ye',
  },
];
