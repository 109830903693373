/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import useWindowDimensions from '../../../../common/hooks/useWindowDimensions';
import faqsData from '../../../faqs/faqs';
import styles from '../login.module.scss';

const Faqs = () => {
  const [itemID, setItemId] = useState('1');
  const { width } = useWindowDimensions();
  const handleClick = (event: { currentTarget: { id: any } }) => {
    setItemId(`${event.currentTarget.id}`);
  };
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return width > 767 ? (
    <section className={styles.faqsWrapper} id="faqs">
      {/* <h1>
        width: {width} ~ height: {height}
      </h1> */}
      <h1 style={{ marginLeft: '10%' }}>FAQs</h1>
      {/* <h5>Main feature description goes here</h5> */}
      <div className={styles.containerFAQs}>
        <div className={styles.leftSection}>
          {faqsData?.map((faq, index) => (
            <div
              className={styles.list}
              id={`${index + 1}`}
              onClick={handleClick}
              style={{
                backgroundColor: itemID === `${index + 1}` ? '#fff' : '#f5f5f5',
              }}
            >
              <div
                className={styles.line}
                style={{
                  display: itemID === `${index + 1}` ? 'block' : 'none',
                }}
              >
                {' '}
              </div>
              <div className={styles.item}>
                <h6>{faq.title}</h6>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.rightSection}>
          <div>
            {faqsData[Number(itemID) - 1]?.sub?.map((sub: any) => (
              <ul>
                <li>
                  <h6>{sub?.title ?? sub}</h6>
                  {sub?.sub?.length ? (
                    sub?.sub?.map((sub1: any) => (
                      <ul>
                        <li>{sub1.title}</li>
                        {sub1.sub?.length ? (
                          sub1.sub?.map((sub2: any) => (
                            <ul>
                              <li>{sub2}</li>
                            </ul>
                          ))
                        ) : (
                          <></>
                        )}
                      </ul>
                    ))
                  ) : (
                    <></>
                  )}
                </li>
              </ul>
            ))}
            <br />
          </div>
        </div>
      </div>
    </section>
  ) : (
    <div className={styles.faqsWrapperMobile} id="faqs">
      <h1
        style={{
          textAlign: 'center',
        }}
      >
        FAQs
      </h1>
      <br />
      {faqsData.map((faq, index) => (
        <Accordion
          expanded={expanded === `panel${index + 1}`}
          onChange={handleChange(`panel${index + 1}`)}
        >
          <AccordionSummary
            expandIcon={
              <span className="material-icons-outlined">expand_more</span>
            }
            aria-controls={`panel${index + 1}bh-content`}
            id={`panel${index + 1}bh-header`}
          >
            <Typography>{faq.title}</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <Typography>
              <div>
                {faq.sub.map((sub: any) => (
                  <>
                    <h6>{sub?.title ?? sub}</h6>
                    {sub?.sub?.length ? (
                      sub?.sub?.map((sub1: any) => (
                        <ul>
                          <li>{sub1.title}</li>
                          {sub1.sub?.length ? (
                            sub1.sub?.map((sub2: any) => (
                              <ul>
                                <li>{sub2}</li>
                              </ul>
                            ))
                          ) : (
                            <></>
                          )}
                        </ul>
                      ))
                    ) : (
                      <></>
                    )}
                    <br />
                  </>
                ))}
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Faqs;
