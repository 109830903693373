/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './footer.module.scss';
import useAlert from '../../../../common/hooks/useAlert';
import { AlertVariant } from '../../../../common/providers/alert.provider';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Footer = (props: any) => {
  const { referredBy } = props;
  const [toggleSellPI, setToggleSellPI] = useState(false);
  const { showAlert } = useAlert();

  const toggleSellInformation = () => {
    localStorage.setItem('isPersonalInformationSell', 'false');
    setToggleSellPI(!toggleSellPI);
  };
  const toggleSellInformationConfirm = () => {
    localStorage.setItem('isPersonalInformationSell', 'true');
    setToggleSellPI(!toggleSellPI);
    showAlert('Updated!', AlertVariant.SUCCESS);
  };

  const getCountDown = () => {
    if (referredBy?.contactDetail?.visitStartTime) {
      const startTime = moment();
      const endTime = moment(referredBy?.contactDetail?.visitStartTime).add(
        30,
        'minutes',
      );
      const remainingTime = endTime.diff(startTime, 'seconds');
      return remainingTime;
    }
    return 0;
  };

  const isEnabledTimer = getCountDown() > 0;

  return (
    <section
      className={[
        styles.footer,
        isEnabledTimer ? styles.footerWithTimer : '',
      ].join(' ')}
    >
      <div className={styles.footerTexts}>
        <div>
          <p>
            <Link to="/about-us" target="_blank">
              About us
            </Link>
            <span>|</span>
            <Link to="/privacy-policy" target="_blank">
              Privacy Policy
            </Link>
            <span>|</span>
            <Link to="/cookie-policy" target="_blank">
              Cookie Policy
            </Link>
            <span>|</span>
            <a href="#" target="_blank" rel="noreferrer">
              Patents
            </a>
            <span>|</span>
            <Link to="/terms-and-conditions" target="_blank">
              Terms of Use
            </Link>
            <span>|</span>
            <Link to="/trust-policy" target="_blank">
              Trust Policy
            </Link>
            <span>|</span>

            <Link
              onClick={toggleSellInformation}
              className={styles.linkClass}
              to=""
            >
              <span style={{ display: 'inline' }}>
                Do Not Sell My Personal Information.
              </span>
            </Link>
            <Dialog open={toggleSellPI} onClose={toggleSellInformation}>
              <DialogContent>
                <h5>Do Not Sell My Personal Information.</h5>
                <br />
                <p>Do you really want to opt out?</p>
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleSellInformationConfirm}>Confirm</Button>
                <Button onClick={toggleSellInformation}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </p>
        </div>
      </div>
      <div className={styles.copyrightText}>
        <p>&copy; Copyright 2024 QuickCam, LLC. All rights reserved.</p>
      </div>
    </section>
  );
};

export default Footer;
