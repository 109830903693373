import React from 'react';
import { Grid } from '@mui/material';
import styles from './game-coins-details.module.scss';
import StackCoins from '../../../../assets/images/StackCoins.png';

const GameCoinsDetails: React.FC = () => {
  return (
    <section className={styles.container} id="features">

      <Grid container spacing={8}>
        <Grid item xs={12} md={8}>
          <h2>QuickCam Coins Cryptocurrency  Pre-ICO Referral System </h2>
          <div >
            <h4>Earn QuickCam Coins: </h4>
            <ul>
              <li>QuickCam sign-up and subscription automatically secures your spot on the &quot;Pre-ICO Whitelist&quot;</li>
              <li>Be first in line to receive QuickCam Coins before the general public</li>
              <li>Get a 3-day free trial and receive an initial amount of QuickCam Coins in a Pending state</li>
              <li>Coins are automatically Confirmed once you subscribe</li>
              <li>Earn more QuickCam Coins as a reward for sharing your QuickCam Referral Link</li>
              <li>The more you share, the more coins you&apos;ll earn from your direct referrals and their referrals</li>
              <li>Upon launch, your confirmed QuickCam Coins will be transferred to your crypto wallet</li>
              <li>QuickCam Coin Stats  provides real-time tracking of your coin holdings</li>
              <li>Act now - limited-time offer on Pre-ICO QuickCam Coin amounts</li>
            </ul>
          </div>
          <div className={styles.coinsStack}>
            <img src={StackCoins} alt="Coins Stack" />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={styles.videoSection}>
            <iframe width="315" height="560"
              src="https://www.youtube.com/embed/aylsnOs7dw4?si=J6xUhF5G7I0gxKXm"
              title="YouTube video player"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
          </div>
        </Grid>
      </Grid>
    </section>
  );
};

export default GameCoinsDetails;
