const faqsData = [
  {
    title: 'How does recording in fast and slow motion in real time work?',
    sub: [
      'Simply touch and hold the record button, then move your finger to the right to record in fast motion, and move your finger to the left for slow motion. Return to the middle for normal speed.',
      'Move your finger from bottom to top to zoom in and back down to zoom out while using the Fast & Slow motion real-time recording.',
      'When you lift up your finger, your video will begin playing back immediately.',
    ],
  },
  {
    title: 'How can I earn money?',
    sub: [
      'Share your referral link by TextInviter using the Invite Wizard.',
      'Share your referral link by DMInviter using the Invite Wizard.',
      'Share your referral link by EmailInviter using the Invite Wizard.',
      'Share your QR code to your unique referral page when in person and on print, such as business cards or webpage.',
      'Share your videos with your referral link in your direct messages.',
      "When your contacts click on the link and confirm you as their referring channel, you'll get a notification that you have new referrals. ",
      'When your direct referrals download QuickCam and subscribe, 50% of the subscription revenues is paid to you for the first 3 months.',
      {
        title: '8 Tiers of Override Commissions',
        sub: [
          {
            title:
              'After the 3rd month, you’ll earn override commissions from 8 referral tiers.',
            sub: [
              '1st Tier: 4%',
              '2nd Tier: 4%',
              '3rd Tier: 4%',
              '4th Tier: 4%',
              '5th Tier: 4%',
              '6th Tier: 4%',
              '7th Tier: 4%',
              '8th Tier: 4%',
            ],
          },
        ],
      },
      'You can also qualify to earn from 5 Generations of Infinity Bonus.',
    ],
  },
  {
    title: 'How much money can I make?',
    sub: [
      'There is no limit.',
      'It all depends on you and the people you share QuickCam with.',
      'We have provided an Income Goal Calculator so you can find out.',
    ],
  },
  {
    title: 'What is the Mobile Dashboard?',
    sub: [
      'The Mobile Dashboard is the control center for Camera settings, account settings, subscriptions, notifications Business Dashboard portal, and Invite Wizard.',
      'Use the Invite Wizard to grow your referral base with TextInviter, Email Inviter, DM Inviter, and QR Code Inviter.',
    ],
  },
  {
    title: 'What is the QuickCam Business Dashboard BackOffice?',
    sub: [
      "It's a management tool with insights and metrics to help you keep track of your referrals, earnings, payout and more.",
      'QuickCam Business Dashboard free Pre-Launch version is available now.',
      'QuickCam Business Dashboard subscription is a separate subscription from QuickCam camera app subscriptions.',
      'QuickCam users can access the QuickCam Business Dashboard directly from the QuickCam camera app.',
      'Subscription option for premium features is for those who want to make money online. Subscriptions to QuickCam Business Dashboard is to be announced.',
    ],
  },
  {
    title: 'What is an Early Adopter?',
    sub: [
      'Early Adopter are users who sign up before May 1, 2023.',
      'As an Early Adopter, your "profile card" in your referral page will be distinguished with the Early Adopter Badge.',
    ],
  },
  {
    title: 'What is a Founding Member?',
    sub: [
      'Founding Members are users who sign up before August 1, 2023.',
      'As a Founding Member, your "profile card" in your referral page will be distinguished with the Founding Member Badge. Early Adopters are also Founding Members.',
    ],
  },
  {
    title: 'What are Premium Gift Card?',
    sub: [
      'Premium Gift Cards extend the 3 Day Free Trial with an additional 4 days of Premium access..',
    ],
  },
  {
    title: 'How do you get Premium Gift Card?',
    sub: [
      'You get 40 complementary Premium Gift Cards you can immediately share when you sign up and register for free.',
      'Subscribe - get more Premium Gift Cards based on your subscription level.',
    ],
  },

  {
    title: 'How can I create a ProManager account?',
    sub: [
      'ProManager is a free single-sign-on identity provider just like Google, Apple, Facebook and Twitter social login that QuickCam uses to create and manage your channel name and password.',
      'You can use the same ProManager account to login to the QuickCam apps and QuickCam Business Dashboard, the companion apps, and all websites and mobile apps with the "Login with ProManager" social login button.',
    ],
  },
];

export default faqsData;
